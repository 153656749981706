import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import React from "react";

type Props = {
  label?: string;
  value?: any;
  onAdd: VoidFunction;
  onMinus: VoidFunction;
};

export default function HowManyGuests({ label, value, onAdd, onMinus }: Props) {
  return (
    <div className="flex items-center justify-between">
      <p className="text-medium text-[14.5px]">{label}</p>
      <div className="flex items-center space-x-5">
        <div
          onClick={onMinus}
          className=" bg-[#D9D9D9] h-6 w-6 rounded-md flex items-center justify-center cursor-pointer"
        >
          <MinusIcon className="w-3 h-3 text-black" />
        </div>

        <p>{value}</p>
        <div
          onClick={onAdd}
          className=" bg-[#D9D9D9] h-6 w-6 rounded-md flex items-center justify-center cursor-pointer"
        >
          <PlusIcon className="w-3 h-3 text-black" />
        </div>
      </div>
    </div>
  );
}
