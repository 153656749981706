import { useState } from "react";
import { PropertyType, errorType } from "./types";
import { RootState } from "../features/property/types";
import { useDispatch, useSelector } from "react-redux";

const initialPropertyState: PropertyType = {
  AccomodationType: "none",
  landloadName: "",
  landloadPhoneNumber: "",
  careTakerName: "",
  caretakerPhoneNumber: "",
  PropertySize: 0,
  place: "",
  NumberofRooms: 0,
  numberofGuests: 1,
  propertPhotosUrl: [],
  amentities: [],
  title: "",
  description: "",
  price: 0,
  Discount: 0,
  PropertyRules: "none",
};
export default function useProperty() {
  const [discountError, setDiscountError] = useState<string>("");
  const dispatch = useDispatch();
  const [propertyValues, setPropertyValues] = useState<PropertyType>(initialPropertyState);
  const [selectedChips, setSelectedChips] = useState<string[]>([]);
  const [errors, setErrors] = useState<errorType>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "Discount") {
      const discountValue = parseFloat(value);
      if (isNaN(discountValue) || discountValue < 0 || discountValue > 100) {
        setDiscountError("Discount value must be a number between 0 and 100");
      } else {
        setDiscountError("");
      }
    }

    setPropertyValues({
      ...propertyValues,
      [name]: value,
    });

    if (true) {
      validateForm();
    }
  };

  const handleClearForm = () => {
    setPropertyValues(initialPropertyState);
  };

  const validateForm = () => {
    let newErrors: errorType = {};

    // Validate landlord name
    // if (!propertyValues.landloadName) {
    //   newErrors.landloadName = "Landlord name is required";
    // }

    // Validate landlord phone number
    // if (!propertyValues.landloadPhoneNumber) {
    //   newErrors.landloadPhoneNumber = "Landlord phone number is required";
    // } else if (typeof propertyValues.landloadPhoneNumber !== "string") {
    //   newErrors.landloadPhoneNumber = "Invalid phone number";
    // } else if (!/^\d{9,10}$/.test(propertyValues.landloadPhoneNumber)) {
    //   newErrors.landloadPhoneNumber = "Invalid phone number";
    // } else if (
    //   propertyValues.landloadPhoneNumber.trim().length < 10 ||
    //   propertyValues.landloadPhoneNumber.trim().length > 10
    // ) {
    //   newErrors.landloadPhoneNumber = "Landlord Phone number must be 10 digits";
    // }

    // Validate caretaker name
    if (!propertyValues.careTakerName) {
      newErrors.careTakerName = "CareTaker name is required";
    }

    // Validate caretaker phone number
    if (!propertyValues.caretakerPhoneNumber) {
      newErrors.caretakerPhoneNumber = "CareTaker phone number is required";
    } else if (typeof propertyValues.caretakerPhoneNumber !== "string") {
      newErrors.caretakerPhoneNumber = "Invalid phone number";
    } else if (!/^\d{9,10}$/.test(propertyValues.caretakerPhoneNumber)) {
      newErrors.caretakerPhoneNumber = "Invalid phone number";
    } else if (
      propertyValues.caretakerPhoneNumber.trim().length < 10 ||
      propertyValues.caretakerPhoneNumber.trim().length > 10
    ) {
      newErrors.caretakerPhoneNumber = "CareTaker Phone number must be 10 digits";
    }

    // Validate accommodation type
    // if (!propertyValues.AccomodationType) {
    //   newErrors.AccomodationType = "Accommodation type is required";
    // }

    // Validate property size
    // if (!propertyValues.PropertySize || isNaN(propertyValues.PropertySize)) {
    //   newErrors.PropertySize = "Property size must be a number";
    // }

    // Validate location
    if (!propertyValues.place) {
      newErrors.place = "Location is required";
    }

    // Validate number of rooms
    if (isNaN(propertyValues.NumberofRooms) || propertyValues.NumberofRooms <= 0) {
      newErrors.NumberofRooms = "Number of rooms must be a non-negative number";
    }

    // Validate number of guests
    if (isNaN(propertyValues.numberofGuests) || propertyValues.numberofGuests <= 0) {
      newErrors.numberofGuests = "Number of guests must be a non-negative number";
    }

    if (!propertyValues.title) {
      newErrors.title = "Title is required";
    }

    // Validate description
    if (!propertyValues.description) {
      newErrors.description = "Description is required";
    }

    // Validate price
    if (isNaN(propertyValues.price) || propertyValues.price <= 0) {
      newErrors.price = "Price must be a non-negative number";
    }

    // Validate discount
    if (
      propertyValues.Discount &&
      (isNaN(propertyValues.Discount) || propertyValues.Discount < 0 || propertyValues.Discount > 100)
    ) {
      newErrors.Discount = "Discount must be a number between 0 and 100";
    }

    setErrors({ ...newErrors });

    // Return true if no errors found
    return Object.keys(newErrors).length === 0;
  };

  const {
    bedrooms,
    livingRoom,
    bathroom,
    Kitchen,
    amenities,
    properties,
    property_error,
    propertPhotosUrl,
    property_message,
    selectedAmenities,
    selectedProperty,
    get_property_loading,
    create_property_loading,
    similar_properties,
    get_similar_property_loading,
    get_booked_property_loading,
    get_draft_property_loading,
    get_live_property_loading,
    live_properties,
    draft_properties,
    booked_properties,
    currentPropertyStatus,
    recent_properties,
    selectedCity,
    get_search_loading,
    currentPropertyPage,
    totalPropertyItems,
    totalPropertyPages,
    currentLivePage,
    totalLivePages,
    totalLiveItems,
    currentBookedPage,
    totalBookedPages,
    totalBookedItems,
    currentDraftPage,
    totalDraftPages,
    totalDraftItems,
    property_rules,
    minPrice,
    maxPrice,
    rooms,
    searchedProperties,
    create_approval_property_loading,
    update_property_loading,
    pending_properties,
    get_pending_property_loading,
    totalPropertySearchPages,
  } = useSelector((state: RootState) => state.property);

  return {
    propertyValues,
    bedrooms,
    amenities,
    livingRoom,
    bathroom,
    Kitchen,
    properties,
    property_error,
    propertPhotosUrl,
    property_message,
    selectedProperty,
    selectedAmenities,
    get_property_loading,
    create_property_loading,
    dispatch,
    handleClearForm,
    setPropertyValues,
    handleInputChange,
    similar_properties,
    get_similar_property_loading,
    get_booked_property_loading,
    get_draft_property_loading,
    get_live_property_loading,
    pending_properties,
    get_pending_property_loading,
    live_properties,
    draft_properties,
    booked_properties,
    currentPropertyStatus,
    recent_properties,
    selectedCity,
    get_search_loading,
    currentPropertyPage,
    totalPropertyItems,
    totalPropertyPages,
    currentLivePage,
    totalLivePages,
    totalLiveItems,
    currentBookedPage,
    totalBookedPages,
    totalBookedItems,
    currentDraftPage,
    totalDraftPages,
    totalDraftItems,
    property_rules,
    selectedChips,
    setSelectedChips,
    minPrice,
    maxPrice,
    rooms,
    searchedProperties,
    create_approval_property_loading,
    update_property_loading,
    discountError,
    validateForm,
    errors,
    totalPropertySearchPages,
  };
}
